.ck.ck-editor__editable_inline {
  border: 1px solid var(--ck-color-base-border);
}
.ck-editor__editable {
  min-height: 300px;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}
